/* eslint-disable @typescript-eslint/no-unused-expressions */
import { runInAction } from 'mobx';
import { sprintf } from 'sprintf-js';
import { parseISO } from 'date-fns';
import qs from 'qs';
import Router from 'next/router';

import { getWamazingSubdomain, getLocale, isCn } from '../../config/locales/i18n';
import ENV from '../../constants/ENV';
import YadoRoomStore from '../../legacy/models/vm_states/YadoState/YadoRoomStore';
import YadoSearchState from '../../legacy/models/vm_states/YadoState/YadoSearchState';
import SentryReporter from '../../models/trackings/SentryReporter';
import { isBrowser, isServer } from './next_helper';
import SearchCondition from '../../models/search/SearchCondition';
import BookingCondition from '../../models/booking/BookingCondition';
import RelativeUrl from '../../models/core/RelativeUrl';
import { Suggestion } from '../../types/SuggestionTypes';
import {
  isAreaSuggestion,
  isRegionSuggestion,
  isPrefectureSuggestion,
  isFeatureSuggestion,
  isYadoSuggestion,
} from '../../helpers/suggestion_helper';
import { GalleryImagesProps } from '../../components/onsen_yado/newTop/TopGallery';
import { Region, onsenRegions } from '../../helpers/onsen_region_helper';
import { AreaSlug } from '../../helpers/onsen_area_helper';
import {
  FilterConditionType,
  FilterConditionTypeUnion,
  filterConditionKeys,
} from '../../models/core/filter/FilterCondition';
import { filterObjectByType, filterObjectFromType, sortObjectByKey } from './objectUtil';

export type LinkProps = {
  as: string;
  href: string;
};

export type LinkItem = {
  label: string;
} & LinkProps;

function qsOption(addQueryPrefix: boolean): { arrayFormat: 'indices'; addQueryPrefix: boolean } {
  return {
    addQueryPrefix,
    arrayFormat: 'indices',
  };
}
const TOP = {
  as: '/yado',
  href: '/yado',
};

export const ONSEN_YADO_TOP_LINK = {
  as: '/yado/onsen',
  href: '/yado/onsen',
};
export const ONSEN_YADO_TOP_AREA_LIST_ELEM_ID = 'area-list';
export const ONSEN_YADO_TOP_AREA_LIST_LINK = {
  as: `/yado/onsen#${ONSEN_YADO_TOP_AREA_LIST_ELEM_ID}`,
  href: '/yado/onsen',
};
export const ONSEN_BOOKINGS_LINK = {
  as: '/yado/onsen/bookings',
  href: '/yado/onsen/bookings',
};

export function createSearchStateByQueryString(queryString: string): YadoSearchState {
  const searchState = new YadoSearchState();
  const query = qs.parse(queryString);
  runInAction(() => {
    if (query.checkinDate && typeof query.checkinDate === 'string') {
      searchState.checkinDate = parseISO(query.checkinDate);
    }

    if (query.checkoutDate && typeof query.checkoutDate === 'string') {
      searchState.checkoutDate = parseISO(query.checkoutDate);
    }

    if (query.areaId) {
      searchState.area = query.areaId;
    }

    if (query.areaName) {
      searchState.areaName = query.areaName;
    }

    if (query.keyword) {
      searchState.keyword = query.keyword;
    }

    if (query.spot) {
      searchState.spot = query.spot;
    }

    if (query.prefecture) {
      searchState.prefecture = query.prefecture;
    }

    if (query.city) {
      searchState.city = query.city;
    }

    if (query.feature) {
      searchState.feature = query.feature;
    }

    if (query.searchMode) {
      searchState.searchMode = query.searchMode;
    }

    if (Array.isArray(query.rooms) && query.rooms.length > 0) {
      const rooms = query.rooms.map((room: any) => new YadoRoomStore(room));
      const roomsProps = rooms.map((room: any) => room.toProps());
      searchState.replaceRooms(roomsProps);
    }
  });
  return searchState;
}

export function createSearchStateByPath(path: string): YadoSearchState {
  const splited = path.split('?');

  if (splited.length <= 1) {
    return new YadoSearchState();
  }

  return createSearchStateByQueryString(splited[splited.length - 1]);
}

export function getAllQuery(path: string, query: object): object {
  const splited = path.split('?');

  if (splited.length <= 1) {
    return query;
  }

  const queryString = splited[splited.length - 1];
  const allQuery = {
    ...qs.parse(queryString),
    ...query,
  };
  return allQuery;
}
export function linkTop(): LinkProps {
  return TOP;
}
export function createYadoDetailLink(
  yadoId: string,
  searchCondition?: SearchCondition | Null,
): LinkProps {
  let asQuery = '';

  if (searchCondition) {
    asQuery = `${qs.stringify(searchCondition, qsOption(true))}`;
  }

  const hrefQuery = qs.stringify(
    {
      yadoId,
    },
    qsOption(true),
  );
  const as = `/yado/yados/${yadoId}${asQuery}`;
  const href = `/yado/yados/show${hrefQuery}`;
  return {
    as,
    href,
  };
}

export function createAreaYadoListLink(
  areaSlug: string,
  searchCondition?: SearchCondition | Null,
  label?: string,
): LinkProps {
  const asQuery = `${qs.stringify(
    {
      ...(searchCondition ? searchCondition.objectForQuery : {}),
      // GAのsite search 用のparam https://support.google.com/analytics/answer/1012264?hl=en
      ...(label ? { q: label } : {}),
    },
    qsOption(true),
  )}`;

  const hrefQuery = qs.stringify(
    {
      areaSlug,
    },
    qsOption(true),
  );
  const as = `/yado/onsen/areas/${areaSlug}${asQuery}`;
  const href = `/yado/onsen/areas/show${hrefQuery}`;
  return {
    as,
    href,
  };
}

export function appendFilterToLink(linkString: LinkProps, filterString: string): LinkProps {
  return {
    as: `${linkString.as}${filterString}`,
    href: `${linkString.href}${filterString}`,
  };
}

export function createAreaDetailLink(
  areaSlug: string,
  searchCondition?: SearchCondition | Null,
  label?: string,
): LinkProps {
  const asQuery = `${qs.stringify(
    {
      ...(searchCondition ? searchCondition.objectForQuery : {}),
      // GAのsite search 用のparam https://support.google.com/analytics/answer/1012264?hl=en
      ...(label ? { q: label } : {}),
    },
    qsOption(true),
  )}`;

  const hrefQuery = qs.stringify(
    {
      areaSlug,
    },
    qsOption(true),
  );
  const as = `/yado/onsen/areas/${areaSlug}/detail${asQuery}`;
  const href = `/yado/onsen/areas/detail/index${hrefQuery}`;
  return {
    as,
    href,
  };
}

export function findRegionFromArea(areaSlug: AreaSlug): Region | undefined {
  return onsenRegions.find(r => r.areaSlugs.includes(areaSlug));
}

export function createRegionDetailLink(
  regionSlug: string,
  searchCondition?: SearchCondition | Null,
  label?: string,
): LinkProps {
  const asQuery = `${qs.stringify(
    {
      ...(searchCondition ? searchCondition.objectForQuery : {}),
      // GAのsite search 用のparam https://support.google.com/analytics/answer/1012264?hl=en
      ...(label ? { q: label } : {}),
    },
    qsOption(true),
  )}`;

  const hrefQuery = qs.stringify(
    {
      regionSlug,
    },
    qsOption(true),
  );
  const as = `/yado/onsen/regions/${regionSlug}${asQuery}`;
  const href = `/yado/onsen/regions/show${hrefQuery}`;
  return {
    as,
    href,
  };
}

export function createPrefectureDetailLink(
  prefectureId: string,
  searchCondition?: SearchCondition | Null,
  label?: string,
): LinkProps {
  const asQuery = `${qs.stringify(
    {
      ...(searchCondition ? searchCondition.objectForQuery : {}),
      // GAのsite search 用のparam https://support.google.com/analytics/answer/1012264?hl=en
      ...(label ? { q: label } : {}),
    },
    qsOption(true),
  )}`;

  const hrefQuery = qs.stringify(
    {
      prefectureId,
    },
    qsOption(true),
  );
  const as = `/yado/onsen/prefectures/${prefectureId}${asQuery}`;
  const href = `/yado/onsen/prefectures/show${hrefQuery}`;
  return {
    as,
    href,
  };
}

export function createFeatureLink(
  featureId: string,
  searchCondition?: SearchCondition | Null,
  label?: string,
): LinkProps {
  const asQuery = `${qs.stringify(
    {
      ...(searchCondition ? searchCondition.objectForQuery : {}),
      // GAのsite search 用のparam https://support.google.com/analytics/answer/1012264?hl=en
      ...(label ? { q: label } : {}),
    },
    qsOption(true),
  )}`;

  const hrefQuery = qs.stringify(
    {
      featureId,
    },
    qsOption(true),
  );
  const as = `/yado/onsen/features/${featureId}${asQuery}`;
  const href = `/yado/onsen/features/show${hrefQuery}`;
  return {
    as,
    href,
  };
}

export function createOnsenYadoDetailLink({
  yadoId,
  searchCondition,
  utmParams,
  label,
}: {
  yadoId: string;
  searchCondition?: SearchCondition | Null;
  utmParams?: { [key: string]: string } | Null;
  label?: string;
}): LinkProps {
  let asQuery = '';

  if (searchCondition) {
    asQuery = `${qs.stringify(
      {
        ...searchCondition.objectForQuery,
        ...(utmParams ?? {}),
        ...(label ? { q: label } : {}),
      },
      qsOption(true),
    )}`;
  }

  const hrefQuery = qs.stringify(
    {
      yadoId,
    },
    qsOption(true),
  );
  const as = `/yado/onsen/yados/${yadoId}${asQuery}`;
  const href = `/yado/onsen/yados/show${hrefQuery}`;
  return {
    as,
    href,
  };
}

export function createOnsenYadoRoomPlanLink(
  yadoId: string,
  roomPlanId: string,
  dinnerId: number,
  searchCondition: SearchCondition,
): LinkProps {
  const url = createOnsenYadoDetailLink({ yadoId, searchCondition });
  const hashQuery = qs.stringify({
    roomPlanId,
    dinnerId,
  });
  return {
    as: `${url.as}#${hashQuery}`,
    href: `${url.href}#${hashQuery}`,
  };
}

export function createOnsenYadoOrderLink(
  yadoId: string,
  roomPlanId: string,
  dinnerId: number,
  searchCondition: SearchCondition,
): LinkProps {
  const asQuery = `${qs.stringify(
    {
      ...searchCondition.objectForQuery,
      dinnerId,
    },
    qsOption(true),
  )}`;
  const hrefQuery = qs.stringify(
    {
      yadoId,
      roomPlanId,
    },
    qsOption(true),
  );
  const as = `/yado/onsen/yados/${yadoId}/room_plans/${roomPlanId}/order${asQuery}`;
  const href = `/yado/onsen/yados/order/create${hrefQuery}`;
  return {
    as,
    href,
  };
}

export function createOnsenYadoOrderPaymentLink(
  yadoId: string,
  roomPlanId: string,
  dinnerId: number,
  bookingCondition: BookingCondition,
): LinkProps {
  const asQuery = qs.stringify(
    {
      ...bookingCondition.objectForQuery,
      dinnerId,
    },
    qsOption(true),
  );
  const hrefQuery = qs.stringify(
    {
      yadoId,
      roomPlanId,
    },
    qsOption(true),
  );
  const as = `/yado/onsen/yados/${yadoId}/room_plans/${roomPlanId}/order/payment${asQuery}`;
  const href = `/yado/onsen/yados/order/payment${hrefQuery}`;
  return {
    as,
    href,
  };
}
export function createOnsenYadoOrderCompleteLink(
  yadoId: string,
  roomPlanId: string,
  dinnerId: number,
  bookingId: string,
): LinkProps {
  const asQuery = `${qs.stringify(
    {
      bookingId,
      dinnerId,
    },
    qsOption(true),
  )}`;
  const hrefQuery = qs.stringify(
    {
      yadoId,
      roomPlanId,
    },
    qsOption(true),
  );
  const as = `/yado/onsen/yados/${yadoId}/room_plans/${roomPlanId}/order/complete${asQuery}`;
  const href = `/yado/onsen/yados/order/complete${hrefQuery}`;
  return {
    as,
    href,
  };
}

export function createOnsenYadoOrderWechatpayLink(
  yadoId: string,
  roomPlanId: string,
  dinnerId: number,
  bookingCondition: BookingCondition,
): LinkProps {
  const query = qs.stringify(
    {
      yadoId,
      roomPlanId,
      dinnerId,
      ...bookingCondition.objectForQuery,
    },
    qsOption(true),
  );
  const url = `/yado/onsen/yados/order/wechatpay${query}`;

  return {
    as: url,
    href: url,
  };
}
export function createOnsenBookingPayLaterCompleteLink(bookingId: string): LinkProps {
  const as = `/yado/onsen/bookings/${bookingId}/paylater/complete`;

  const hrefQuery = qs.stringify(
    {
      bookingId,
    },
    qsOption(true),
  );
  const href = `/yado/onsen/bookings/paylater/complete${hrefQuery}`;
  return {
    as,
    href,
  };
}

export function createOnsenBookingsLink(): LinkProps {
  const as = '/yado/onsen/bookings';
  const href = '/yado/onsen/bookings';
  return {
    as,
    href,
  };
}

export function createOnsenBookingDetailLink(bookingId: string): LinkProps {
  const as = `/yado/onsen/bookings/${bookingId}`;

  const hrefQuery = qs.stringify(
    {
      bookingId,
    },
    qsOption(true),
  );
  const href = `/yado/onsen/bookings/show${hrefQuery}`;
  return {
    as,
    href,
  };
}
export function createOnsenBookingPayLaterPayLink(bookingId: string): LinkProps {
  const as = `/yado/onsen/bookings/${bookingId}/paylater/pay`;

  const hrefQuery = qs.stringify(
    {
      bookingId,
    },
    qsOption(true),
  );
  const href = `/yado/onsen/bookings/paylater/pay${hrefQuery}`;
  return {
    as,
    href,
  };
}
export function createOnsenBookingDetailPickupRequestLink(bookingId: string): LinkProps {
  const as = `/yado/onsen/bookings/${bookingId}/pickup_request`;

  const hrefQuery = qs.stringify(
    {
      bookingId,
    },
    qsOption(true),
  );
  const href = `/yado/onsen/bookings/pickup_request${hrefQuery}`;
  return {
    as,
    href,
  };
}

export function moveToSearchResultPage({
  suggestion,
  currentPageAreaSlug,
  currentPageRegionSlug,
  currentPagePrefectureId,
  currentPageFeatureId,
  searchCondition,
}: {
  suggestion: Suggestion;
  currentPageAreaSlug?: string;
  currentPageRegionSlug?: string;
  currentPagePrefectureId?: string;
  currentPageFeatureId?: string;
  searchCondition?: SearchCondition | Null;
}) {
  const { label } = suggestion;
  const areaSlug = isAreaSuggestion(suggestion) ? suggestion.slug : null;
  const regionSlug = isRegionSuggestion(suggestion) ? suggestion.slug : null;
  const prefectureId = isPrefectureSuggestion(suggestion) ? suggestion.slug : null;
  const featureId = isFeatureSuggestion(suggestion) ? suggestion.slug : null;
  const yadoId = isYadoSuggestion(suggestion) ? suggestion.yadoId : null;
  if (areaSlug) {
    const link = createAreaYadoListLink(areaSlug, searchCondition, label);

    if (currentPageAreaSlug === areaSlug) {
      Router.replace(link.href, link.as).then(() => {
        window.scrollTo(0, 0);
      });
      return;
    }

    Router.push(link.href, link.as).then(() => {
      window.scrollTo(0, 0);
    });
    return;
  }

  if (regionSlug) {
    const link = createRegionDetailLink(regionSlug, searchCondition, label);

    if (currentPageRegionSlug === regionSlug) {
      Router.replace(link.href, link.as).then(() => {
        window.scrollTo(0, 0);
      });
      return;
    }

    Router.push(link.href, link.as).then(() => {
      window.scrollTo(0, 0);
    });
    return;
  }

  if (prefectureId) {
    const link = createPrefectureDetailLink(prefectureId, searchCondition, label);

    if (currentPagePrefectureId === prefectureId) {
      Router.replace(link.href, link.as).then(() => {
        window.scrollTo(0, 0);
      });
      return;
    }

    Router.push(link.href, link.as).then(() => {
      window.scrollTo(0, 0);
    });
    return;
  }

  if (featureId) {
    const link = createFeatureLink(featureId, searchCondition, label);

    if (currentPageFeatureId === featureId) {
      Router.replace(link.href, link.as).then(() => {
        window.scrollTo(0, 0);
      });
      return;
    }

    Router.push(link.href, link.as).then(() => {
      window.scrollTo(0, 0);
    });
    return;
  }

  if (yadoId) {
    const link = createOnsenYadoDetailLink({ yadoId, searchCondition, label });

    Router.push(link.href, link.as).then(() => {
      window.scrollTo(0, 0);
    });
    return;
  }

  SentryReporter.captureException('unreachable');
}

export function replaceOnsenAreaYadoListUrl(areaSlug: string, searchCondition: SearchCondition) {
  const link = createAreaYadoListLink(areaSlug, searchCondition);
  Router.replace(link.href, link.as);
}

export function pushOnsenYadoOrderUrl(
  yadoId: string,
  roomPlanId: string,
  dinnerId: number,
  searchCondition: SearchCondition,
) {
  const link = createOnsenYadoOrderLink(yadoId, roomPlanId, dinnerId, searchCondition);
  Router.push(link.as); // Router.pushのバグ？で予約情報入力ページから宿詳細に戻れない現象が発生するのでCSR遷移を無効化
}

export function replaceOnsenYadoOrderUrl(
  yadoId: string,
  roomPlanId: string,
  dinnerId: number,
  searchCondition: SearchCondition,
) {
  const link = createOnsenYadoOrderLink(yadoId, roomPlanId, dinnerId, searchCondition);
  return Router.replace(link.href, link.as);
}
export function extractHashString(href: string): string {
  const hashString = new URL(href).hash.slice(1);
  return hashString;
}

export function extractHashQuery(href: string): object {
  return new RelativeUrl(href).hashParams;
}

export function extractFilterFromQuery(href: string): Partial<FilterConditionType> {
  const { searchParams } = new RelativeUrl(href);
  const filterQuery: Partial<FilterConditionType> = filterObjectByType(
    searchParams,
    filterConditionKeys,
  );
  return filterQuery;
}

export function setHashQuery(
  query: Record<string, FilterConditionTypeUnion>,
  isSearchForm: boolean = false,
): void {
  if (isServer()) return;
  if (!isSearchForm) {
    const queryString = qs.stringify(query);
    if (queryString) {
      window.location.hash = queryString;
    } else {
      clearHash();
    }
  } else {
    const relUrl = new RelativeUrl(Router.asPath);
    const { searchParams } = relUrl;
    const urlPath = relUrl.path;
    const noFilterParams = filterObjectFromType(searchParams, filterConditionKeys);
    const noFilterString = qs.stringify(noFilterParams);
    const sortedQuery = sortObjectByKey<FilterConditionTypeUnion>(query);
    const queryString = qs.stringify(sortedQuery);
    const newQuery = `${urlPath}${noFilterString || queryString ? '?' : ''}${noFilterString}${
      noFilterString && queryString ? '&' : ''
    }${queryString}`;

    window.history.replaceState(null, document.title, newQuery);
  }
}

export function clearHash(): void {
  if (isServer()) return;
  if (window.history) {
    const url = new URL(window.location.href);
    url.hash = '';
    window.history.replaceState(null, document.title, url.href);
  }
}

export function getSubDomainFromHost(host: string): string {
  return (host || '').split('.')[0];
}

export function subdomainUrl(): string {
  if (isCn()) {
    return ENV.ENVIRONMENT === 'production'
      ? 'https://p.wamazing-cn.com'
      : 'https://staging.wamazing-cn.com';
  }
  return sprintf(ENV.WAMAZING_COM_ENDPOINT, getWamazingSubdomain());
}

export function subdomainMediaUrl(): string {
  return sprintf(ENV.WAMAZING_MEDIA_ENDPOINT, getWamazingSubdomain());
}

export function getMediaSearchResultUrl(keyword: string): string {
  return `${subdomainMediaUrl()}/${qs.stringify({ s: keyword }, qsOption(true))}`;
}

export function signInUrl(redirectUrl?: string | Null): string {
  const url = `${subdomainUrl()}/account/auth/signin`;

  if (!redirectUrl) {
    return url;
  }

  return `${url}${qs.stringify(
    {
      redirect_url: redirectUrl,
    },
    qsOption(true),
  )}`;
}

export function signOutParams(
  redirectPath?: string | Null,
): {
  path: string;
  redirectUrl?: string;
} {
  const path = '/snow/auth/session';
  const internalPath = signOutInternalPath(redirectPath);

  if (internalPath && isBrowser() && window.location) {
    const splited = window.location.href.split('/');

    const originUrl: string = `${splited[0]}//${splited[2]}`;
    const redirectUrl = `${originUrl}${internalPath}`;
    return {
      path,
      redirectUrl,
    };
  }

  return {
    path,
  };
}

export function signOutInternalPath(redirectPath?: string | Null): string | Null {
  if (redirectPath) {
    return `/yado/sessions/signout${qs.stringify(
      {
        redirect_path: redirectPath,
      },
      qsOption(true),
    )}`;
  }

  return null;
}

export function registrationUrl(redirectUrl?: string | Null): string {
  const url = `${subdomainUrl()}/account/auth/signup`;

  if (!redirectUrl) {
    return url;
  }

  return `${url}${qs.stringify(
    {
      redirect_url: redirectUrl,
    },
    qsOption(true),
  )}`;
}

export function wechatRegistrationUrl(redirectUrl?: string | Null): string {
  const url = `${subdomainUrl()}/account/auth/signup`;

  if (!redirectUrl) {
    return url;
  }

  return `${url}${qs.stringify(
    {
      redirect_url: redirectUrl,
      timestamp: new Date().getTime() + Math.random(),
    },
    qsOption(true),
  )}`;
}

export function addCreditCardUrl(redirectUrl?: string | Null): string {
  const url = `${subdomainUrl()}/snow/payment/credit_cards/new`;

  if (!redirectUrl) {
    return url;
  }

  return `${url}${qs.stringify(
    {
      redirect_url: redirectUrl,
    },
    qsOption(true),
  )}`;
}

export function ordersAccountUrl(): string {
  return `${subdomainUrl()}/account/me/orders`;
}

export function addCardIframeUrl(): string {
  return `${subdomainUrl()}/account/me/payment/add_card?isIframe=true`;
}

export function listCardsUrl(): string {
  return `${subdomainUrl()}/account/me/payment`;
}

// サブドメイン(tw|hk|www|cn)を.に置き換える => .(staging-)wamazing.com
// cnのステージング、プロダクションの場合はyadoを.に置き換える => .(staging|p).wamazing-cn.com
export function getDomainForCookie(): string | Null {
  if (isBrowser() && window.location && window.location.href) {
    const url = new URL(window.location.href);

    if (url.hostname) {
      const domain = url.hostname.replace(/.+?\./, '.');
      return domain;
    }
  }

  return null;
}

export function createHrefOnServer(req: any): string {
  const host = req.headers['x-forwarded-host'] || req.headers.host;
  return `${req.protocol}://${host}${req.originalUrl}`;
}

export function extractDinnerId(asPath: string): number {
  const paramQuery = new RelativeUrl(asPath).searchParams;
  return Number(paramQuery.dinnerId) || 0;
}

export function extractBookingId(asPath: string): string {
  const paramQuery = new RelativeUrl(asPath).searchParams;
  return paramQuery.bookingId;
}

export function getZendeskUrl() {
  const zendeskBase = 'https://wamazing.zendesk.com/hc/';

  switch (getLocale()) {
    case 'zh_tw': {
      return `${zendeskBase}zh-tw`;
    }

    case 'zh_hk': {
      return `${zendeskBase}zh-hk`;
    }

    case 'zh_cn': {
      return `https://mp.weixin.qq.com/mp/appmsgalbum?__biz=MzU0NjkzNzQ1NA==&action=getalbum&album_id=3003643830908370948#wechat_redirect`;
    }

    case 'ja': {
      return `${zendeskBase}ja`;
    }

    default: {
      return `${zendeskBase}zh-tw`;
    }
  }
}

export function isCnHost(host: string): boolean {
  return (
    ['staging.wamazing-cn.com', 'p.wamazing-cn.com'].includes(host) ||
    (ENV.ENVIRONMENT === 'development' && getSubDomainFromHost(host) === 'cn') // localではcn.lvh.meで見れるように
  );
}

export function isJpHost(host: string): boolean {
  return (
    ['jp.staging-wamazing.com', 'wamazing-onsen-web-production.herokuapp.com'].includes(host) ||
    getSubDomainFromHost(host) === 'jp' // localではjp.lvh.meで見れるように
  );
}
export function getCompanyInformationUrl() {
  const companyInformationBase = 'https://corp-global.wamazing.com/';

  switch (getLocale()) {
    case 'zh_tw': {
      return `${companyInformationBase}tw`;
    }

    case 'zh_hk': {
      return `${companyInformationBase}hk`;
    }

    default: {
      return `${companyInformationBase}en`;
    }
  }
}

export function getGoogleMapLinkFromCoordinate(latitude: number, longitude: number) {
  return `https://www.google.com/maps?q=${latitude},${longitude}`;
}

export function createFeatureArticleLink(featureArticleId: string): LinkProps {
  return {
    href: `/yado/onsen/featured/show?featureArticleId=${featureArticleId}`,
    as: `/yado/onsen/featured/${featureArticleId}`,
  };
}

export function createViewAllLink(urlName: string): LinkProps {
  const as = `/yado/onsen/${urlName}`;
  const href = `/yado/onsen/${urlName}`;
  return {
    as,
    href,
  };
}

export function getActivityUrl(id: string) {
  const utmParams = '?utm_source=wa-yado&utm_medium=referral&utm_campaign=kinosaki_act';
  return `https://${getWamazingSubdomain()}.wamazing.com/activity/detail/${id}${utmParams}`;
}

export function ticketWebUrl() {
  return `${subdomainUrl()}/ticket`;
}

export function getWatopUrl() {
  const utmParams =
    '?utm_source=wa-yado&utm_medium=referral&utm_campaign=WAmazingStayisDiscontinuingOperations';
  return `https://${getWamazingSubdomain()}.wamazing.com/top/${utmParams}`;
}

export function getTopGalleryBannerHref(pic: GalleryImagesProps) {
  if (pic.type === 'featured') {
    return `/yado/onsen/featured/show?featureArticleId=${pic.featureId}`;
  } else if (pic.link) {
    return pic.link;
  }
  return isCn()
    ? 'https://p.wamazing-cn.com/campaign/summer2024/?utm_source=wa-other&utm_campaign=summer2024&utm_medium=banner'
    : `https://${getWamazingSubdomain()}.wamazing.com/campaign/fall-leaves2024?utm_source=wa-yado&utm_medium=referral&utm_campaign=topbanner`;
}

export function getBaseURI() {
  return isCn() ? ENV.WAMAZING_YADO_CN_API_ENDPOINT : ENV.WAMAZING_YADO_API_ENDPOINT;
}

export function getReplaceBaseURI() {
  return isCn()
    ? ENV.WAMAZING_YADO_REPLACE_CN_API_ENDPOINT
    : ENV.WAMAZING_YADO_REPLACE_API_ENDPOINT;
}
